.layout {
  
}
.layout_content {
  box-shadow:0px 20px 20px -20px rgba(0,0,0,0.2);
}
.layout_left {
  width: 100%;
  margin:auto;
}
.input{
  padding:10px 20px; background-color:#f2f2f2; margin-bottom:10px;
  border:1px solid rgba(0,0,0,0.2); border-radius:50px;
}
@media (min-width: 768px){
  .layout_left {
      width:400px;   
  }
}
@media (min-width: 1200px){
  .layout_left {
      width:500px;    
  }
}
@media (min-width: 1400px){
  .layout_left {
      width:600px;    
  }
}