.site-section {
  transform-origin: top;
  transform: scale(0.98);
  transition: 300ms;
  margin: 10px auto 100px;
  /* width: 1280px; */
}

.left-panel {
  position: fixed;
  top: 72px;
}

.right-panel {
  position: fixed;
  right: 20px;
  top: 72px;
  bottom: 0px;
}

.right-panel.open-panel {
  width: 350px;
}

.open-editor {
  transform: scale(0.73);
  transform-origin: 80px 0px !important;
}

.section-panel,
.site-settings-cls {
  width: 800px;
}
.sec-details-panel {
  height: 450px;
  overflow: auto;
}

.pages-layout {
  width: 800px;
  height: calc(100vh - 200px);
}
.pages-layout .pages-list {
  height: calc(100vh - 300px);
  overflow: auto;
}
.pages-layout .pages-settings-layout {
  /*height: calc(100vh - 350px);*/
  overflow: auto;
}
.generate-page-layout {
  max-width: 1200px;
}
.create-page-layout {
  width: 500px;
}
.create-project-layout {
  width: 650px;
}
.toggel-input:checked ~ .dot {
  transform: translateX(100%);
  background-color: rgba(21, 231, 189, 1);;
}

.icon-spnner {
  font-size: 60px;
}

.connections-cls {
  width: 800px;
  max-height: calc(100vh - 200px);
}

.favicon-cls .uppy-DragDrop-inner {
  padding: 5px 5px;
  font-size: 0.65rem !important;
}

.favicon-cls .uppy-DragDrop-arrow {
  height: 12px;
  width: 20px;
  margin-bottom: 5px;
}

.favicon-cls .s3-uploader-cls {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
}

.favicon-cls .s3-uploader-img {
  border: 1px solid rgba(107, 114, 128);
  flex: 1;
  padding: 2px;
  margin-left: 20px;
}
.favicon-cls .s3-uploader-img img {
  max-height: 48px;
}

.sly-preview-parent {
  height: calc(100vh - 70px);
}
.sly-preview-wrapper {
  height: 100%;
  width: 100%;
  margin: auto;
}
.sly-preview-wrapper.activeRightPanel.mobile, .sly-preview-wrapper.activeRightPanel.tablet {
  width: calc(100% - 370px);  
  margin-left: 20px;
}
.sly-preview-wrapper.mobile iframe {
  width: 415px; 
  margin:auto;
}
.sly-preview-wrapper.tablet iframe{
  width: 834px;
  margin:auto;
   /*margin-left: 200px;*/
}

#sly-preview-iframe {
  height: 100%;
}


.Dropdown_button_icon{padding:0px!important;}