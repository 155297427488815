.plan-html > * + * {
  @apply sly-prod-mt-0 sly-prod-mb-4;
}

.plan-html li + li {
  @apply sly-prod-mt-1;
}

.plan-html li > p + p {
  @apply sly-prod-mt-6;
}

.plan-html strong {
  @apply sly-prod-font-semibold;
}

.plan-html a {
  @apply sly-prod-text-blue-600 sly-prod-font-semibold;
}

.plan-html strong a {
  @apply sly-prod-font-bold;
}

.plan-html h1 {
  @apply sly-prod-leading-tight sly-prod-text-4xl sly-prod-font-semibold sly-prod-mb-4 sly-prod-mt-6 sly-prod-pb-2;
}

.plan-html h2 {
  @apply sly-prod-leading-tight sly-prod-text-2xl sly-prod-font-semibold sly-prod-mb-4 sly-prod-mt-6 sly-prod-pb-2;
}

.plan-html h3 {
  @apply sly-prod-leading-snug sly-prod-text-lg sly-prod-font-semibold sly-prod-mb-4 sly-prod-mt-6;
}

.plan-html h4 {
  @apply sly-prod-leading-none sly-prod-text-base sly-prod-font-semibold sly-prod-mb-4 sly-prod-mt-6;
}

.plan-html h5 {
  @apply sly-prod-leading-tight sly-prod-text-sm sly-prod-font-semibold sly-prod-mb-4 sly-prod-mt-6;
}

.plan-html h6 {
  @apply sly-prod-leading-tight sly-prod-text-sm sly-prod-font-semibold sly-prod-text-gray-600 sly-prod-mb-4 sly-prod-mt-6;
}

.plan-html blockquote {
  @apply sly-prod-text-base sly-prod-border-l-4 sly-prod-border-gray-300 sly-prod-pl-4 sly-prod-pr-4 sly-prod-text-gray-600;
}

.plan-html ul {
  @apply sly-prod-text-base sly-prod-pl-8 sly-prod-list-disc;
}

.plan-html ol {
  @apply sly-prod-text-base sly-prod-pl-8 sly-prod-list-decimal;
}

.plan-html table {
  @apply sly-prod-text-base sly-prod-border-gray-600;
}

.plan-html th {
  @apply sly-prod-border sly-prod-py-1 sly-prod-px-3;
}

.plan-html td {
  @apply sly-prod-border sly-prod-py-1 sly-prod-px-3;
}

/* Override pygments style background color. */
.plan-html .highlight pre {
  @apply sly-prod-bg-gray-100 !important;
}
